import { render, staticRenderFns } from "./AllOrdersList.vue?vue&type=template&id=113a119e&scoped=true&"
import script from "./AllOrdersList.vue?vue&type=script&lang=js&"
export * from "./AllOrdersList.vue?vue&type=script&lang=js&"
import style0 from "./AllOrdersList.vue?vue&type=style&index=0&id=113a119e&lang=scss&scoped=true&"
import style1 from "./AllOrdersList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113a119e",
  null
  
)

export default component.exports